<template>
  <v-app>
    <v-card class="bgs rounded-0 d-flex justify-center" flat  
      width="100%"
      height="100%"
    >
      <v-container>
        <h2 class="text-center mt-6 font24 mb-4">
          {{ $t("taoify.shop用户注册协议") }}
        </h2>
        <!-- <div class="titlee">
          一、{{ $t("taoify.shop用户服务协议的确认") }}
        </div>
        <div class="titlee">二、{{ $t("taoify.shop用户服务相关定义") }}</div>
        <div class="titlee">
          三、{{ $t("taoify.shop为您提供的服务内容") }}
        </div>
        <div class="titlee">四、{{ $t("账户的注册，使用和注销") }}</div>
        <div class="titlee">五、{{ $t("taoify.shop自动扣款授权") }}</div>
        <div class="titlee">
          六、{{ $t("使用taoify.shop用户服务的注意事项") }}
        </div>
        <div class="titlee">
          七、{{ $t("用户合法使用taoify.shop服务的承诺") }}
        </div>
        <div class="titlee">八、{{ $t("用户权益保障及信息保护") }}</div>
        <div class="titlee">九、{{ $t("不可抗力、免责及责任限制") }}</div>
        <div class="titlee">十、{{ $t("配套规则和协议") }}</div>
        <div class="titlee">十一、{{ $t("知识产权的保护") }}</div>
        <div class="titlee">十二、{{ $t("法律适用") }}</div> -->
        <div class="content mt-4">
          {{
            $t(
              "感谢您选择taoify.shop服务taoify.shop用户注册协议(以下简称本协议”)由taoify.shop(我们”)和您答订。taoify.shop的各项电子服务的所有权和运作权归taoify.shop所有，用户同意所有注册条款并完成注册程序，才能成为taoify.shop正式用户，本协议条款是处理双方权利义务的契约，始终有效。"
            )
          }}
        </div>
        <div class="titlee">
          {{ $t("一、taoify.shop用户服务协议的确认") }}
        </div>
        <div class="content">
          1.
          {{
            $t(
              "用户在使用taoify.shop所提供的各项服务的同时，承诺接受并遵守各项相关规则的规定，用户确认本服务协议后，本服务协议即在用户和taoify.shop之间产生法律效力，请用户务必在使用之前认真阅读全部服务协议内容，如有何疑问，您可通过taoify.shop官方网站的在线客服或邮箱 97668216@qq.com进行咨询，以便我们为您解释和说明，您通过页面点击或其他方式确认即表示您已同意本协议。"
            )
          }}
        </div>
        <div class="content">
          2.
          {{
            $t(
              "为给您提供更好的服务以及随着taoify.shop业务的发展，本协议也会随之更新。如我们对本协议进行修改，我们将通过在taoify.shop网站上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。对于重大变更，我们还会提供更为显著的通知(我们会通过包括但不限于邮件短信或在浏览页面做特别提示等方式，说明本协议的具体变更内容)，若您无法同意变更修改后的协议内容，您有权停止使用相关服务;双方协商一致的，也可另行变更相关服务和对应协议内容，登录或继续使用“服务”将表示您接受并同章协议修改后的全部内容"
            )
          }}
        </div>
        <div class="content">
          3.
          {{
            $t(
              "您需遵守您所属国家或地区的法律，目您确认，订立并履行本协议不违反您所属，所居住或开展经营活动或其他业务的国家或地区的法律法规。"
            )
          }}
        </div>
        <div class="titlee">{{ $t("二、taoify.shop用户服务相关定义") }}</div>
        <div class="content">
          1.
          {{
            $t(
              "taoify.shop用户账户(或称”账户”:指在您通过平台注册并通过taoify.shop用户身份验证后，我们为您开立的平台使用账户。"
            )
          }}
        </div>
        <div class="content">
          2.
          {{
            $t(
              "taoify.shop用户登录邮箱:指您设置的用于登录用户账户的电子邮箱。"
            )
          }}
        </div>
        <div class="content">
          3.
          {{
            $t(
              "身份要素：指我们用于识别您身份的信息要素，包括但不限于您的taoify.shop用户账户、密码、邮箱校验码、电话号码、手机号码等信息。"
            )
          }}
        </div>
        <div class="content">
          4.
          {{
            $t(
              "taoify.shop用户网站:除本协议另有规定外，指taoify.shop及/或taoify.shop用户操作后台。"
            )
          }}
        </div>
        <div class="content">
          5.
          {{
            $t(
              "余额管理:指基于taoify.shop用户账户在您经营过程中对账号进行的充值、扣款、提现等服务。"
            )
          }}
        </div>
        <div class="content">
          6.
          {{
            $t(
              "taoify.shop极简用户账号:指用户初次使用taoify.shop网站进行注册成功后获得使用的用户账号。"
            )
          }}
        </div>
        <div class="content">
          7.
          {{
            $t(
              "taoify.shop平台:指taoify.shop自主开发并与其系统完美对接的独立站建站工具。"
            )
          }}
        </div>
        <div class="content">
          8.
          {{
            $t(
              "一件代发服务:指为您采购中国境内一个或多个商品并在打包完成后寄送到您的指定收货地址的整个服务流程。"
            )
          }}
        </div>
        <div class="content">
          9.
          {{
            $t(
              "店铺代运营服务:指为您代运营和管理您的独立站店铺。具体是指自受您委托之时起(签订店铺代运营合同/SOP)，基于您选择的店铺服务项对您的店铺进行代运营操作的整个过程。"
            )
          }}
        </div>
        <div class="titlee">
          {{ $t("三、taoify.shop为您提供的服务内容") }}
        </div>
        <div class="content mt-1">
          {{
            $t(
              "taoify.shop用户服务是我们通过极简用户账号+代运营操作模式向您提供受您委托的产品一件代发、店铺代运营(仅限于BuckyShop/Shopifv/Woocommerce店铺)等国际电商业务运营管理服务。"
            )
          }}
        </div>

        <div class="content mt-3">{{ $t("(一) 基本服务") }}</div>
        <div class="content mt-2 mb-3">
          {{
            $t(
              "taoify.shop默认免费为所有用户提供以下软件功能和服务，足以让您自助一站式开启电商事业:"
            )
          }}
        </div>
        <ul>
          <li>
            {{
              $t(
                "建店/关联店铺，支持绑定Shopifv/WooCommerce/taoify.shop店铺平台。taoify.shop为您一键创建独立站店铺，同时taoify.shop能支持绑定您的独立站店铺并提供选择商品、订单管理、包裹管理和国际物流等全流程电商服务。"
              )
            }}
          </li>
          <li>
            {{ $t("海量商品搜索/上架，支持中国电商平台(淘宝、天猫、1688)。") }}
          </li>
          <li>
            {{
              $t(
                "专业的客户经理为您提供包含店铺运营，产品采购，质量检验，仓储，包裹服务，物流和售后服务的一站式解决方案。"
              )
            }}
          </li>
        </ul>
        <div class="content mt-3 mb-3">{{ $t("(二)	服务项详情") }}</div>
        <ul>
          <li>
            {{
              $t(
                "基础服务，基础服务是您在使用系统时涉及的服务项。taoify.shop为每位用户提供一定的免费次数，您需为超出的次数付费。包括对接的第三方平台，商品搜索/编辑、订单同步到taoify.shop全球寄送等基础服务。"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "增值服务，增值服务仅在商品采购、仓储打包、国际物流发货过程中可能产生收费。该服务包括商品增值服务(到付件代付、修剪线头、商品盘点、商品个性化贴标等)和包裹增值服务(EPE泡沫填充物、纸护角卡条、拉伸膜、木架加固等)。"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "店铺服务，taoify.shop为您提供多样化的店铺装修、商品管理、订单管理等店铺运营管理收费服务，包括店铺搭建托管、产品托管、订单托管、店铺运营托管等。服务项详情和收费标准请查看:"
              )
            }}<a class="content primary--text"  :href="`${$store.state.locationOrigin}`"
              >{{$store.state.locationOrigin}}</a
            >
          </li>
        </ul>
        <div class="content mt-3 mb-2">{{ $t("(三)	商品存储与销毁") }}</div>
        <ul>
          <li>
            {{
              $t(
                "商品存储，每一件寄送至taoify.shop仓库的商品，都可以享受30天的免费存储服务(从商品签收时间开始计算)。并且，在30天免费存储服务到期后，您可以继续购买付费存储服务延长存储期限，通常，我们会在存储期即将到期前3天，以及到期当天向您发送邮件提醒，请您及时完成商品寄送，或购买付费存储服务延长商品存储期。"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "商品销毁，如果您在商品存储服务已过期30天后，商品仍在仓库未寄送，目没有购买任何付费存储服务或支付相关服务费用，我们将对过期商品讲行销股，已销毁的商品将无法继续存储或寄出。通常，我们会在商品即将销毁前3天，以及销毁当天向您发送邮件提醒。商品销毁后，您将没有权利向taoify.shop索要已销毁的商品，或申请相关赔偿"
              )
            }}
          </li>
        </ul>
        <div class="titlee">{{ $t("四、账户的注册，使用和注销") }}</div>
        <div class="content mt-3">{{ $t("(一)	注册") }}</div>
        <div class="content mt-2">
          {{
            $t(
              "当您进入我们的网站账号注册页面(https://taoify.shop)填写必要信息并完成注册，通过taoify.shop用户验证后取得taoify.shop用户账户，您即可使用taoify.shop为您提供的用户服务，享受taoify.shop后台相应的操作权限。您有义务在注册时提供自己的真实材料，并保证如电子邮件地址等内容的有效性和安全性保证taoify.shop可通过上述联系方式与您取得联系。同时，您也有义务在相关资料实际变更时及时更新注册资料，并同时保证不以他人资料在taoify.shop进行注册或认证。基于风险控制的需要，taoify.shop平台可决定是否向您提供用户账户。"
            )
          }}
        </div>
        <div class="content mt-3 mb-3">{{ $t("(二)	使用") }}</div>
        <ul>
          <li>
            {{
              $t(
                "身份要素是我们识别您身份的依据，请您务必妥善保管。使用身份要素进行的操作，发出的指令视为您本人做出。因您的原因造成的账户，密码等信息被冒用或盗用，由此引起的风险和损失需要由您自行承担。"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "您的taoify.shop用户账户在新设备首次登录时，我们可能通过邮件认证的方式识别您的身份。"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "用户账户仅限您本人使用，请勿转让，借用，赠与，由此引起的风险和损失需要由您自行承担。"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "为了您的账号安全，在使用taoify.shop账号服务时，请您事先自行核实操作规则或谨慎决定是否授权给代运营团队操作"
              )
            }}
          </li>
          <li>
            {{
              $t(
                "您在taoify.shop平台上发生的所有交易和扣款(包括BuckyShop平台)，都表示您授权我们会根据各个服务项的收费标准和事先签订的双方合作条款等操作规则由taoify.shop系统自动扣缴或由我们财务手动扣缴"
              )
            }}
          </li>
        </ul>
        <div class="titlee">{{ $t("五、taoify.shop自动扣款授权") }}</div>
        <div class="content">
          1.
          {{
            $t(
              "本协议所述授权内容由您向taoify.shop平台承诺确认，且有授权之法律效力，请您务必审慎阅读，充分理解此条款内容，特别是免除或者限制责任的条款，您应重点阅读。除非您已阅读并接受此条款，否则您无权使用taoify.shop平台的自动续费、自动扣款等服务。您同意此条款即视为您已向taoify.shop平台提供自动扣款授权，并自愿承担由此导致的一切法律后果。如您有任何舒问，应向taoify.shop客服(电话+86 15038350530)咨询"
            )
          }}
        </div>
        <div class="content">
          2.
          {{
            $t(
              "taoify.shop平台在首次扣款时需要获取您的授权验证，您勾选授权后即视为您授权taoify.shop可依据您所选服务项目的交易指令从您的taoify.shop账户余额中扣划对应的款项月taoify.shop后续在扣款时，将无需再验证您的授权信息。"
            )
          }}
        </div>
        <div class="content">
          3.
          {{
            $t(
              "您在扣款账户内必须预留足够被扣划的余额，否则因账户余额不足导致无法及时扣款或扣款错误，失败，责任由您自行承担。因不可归责事由，导致的不能及时划账付款、划账错误等责任与taoify.shop平台无关"
            )
          }}
        </div>
        <div class="content">
          4.
          {{
            $t(
              "您确认，因taoify.shop平台的原因导致您遭受经济损失的，由您与taoify.shop平台协商解决"
            )
          }}
        </div>
        <div class="titlee">
          {{ $t("六、使用taoify.shop用户服务的注意事项") }}
        </div>
        <div class="content mt-3 mb-3">{{$t("(一)	 极简账号")}}</div>
        <ul>
          <li>{{$t('极简账号配备一件代发电商业务经营基本功能，包括绑定店铺，自动履单，自动提包等功能。新注册用户账号系统认设置为极简账号，在店铺经营过程中需进行订单操作，则默认授权由我们代运营团队代其处理，处理讨程中产生的服务或操作费用按taoify.shop官网公示的服务费用或事先双方协商的服务费用由系统自动扣缴或财务手动扣缴。')}}</li>
        </ul>

        <div class="content mt-3 mb-3">{{$t("(二)	 为有效保障您使用我们服务时的合法权益，我们提醒您注意身份验证事项：")}}</div>
        <ul>
          <li>{{$t('您在注册，使用我们服务的过程中，请您提供合法，真实，有效，准确并完整的资料(相据实际操作票要，可能包括真实姓名，国籍，营业执照，联系方式，从事职业通讯地址等)。为了能将账户信息变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知我们或者自行在taoify.shop后台做调整更新。')}}</li>
        </ul>

        <div class="content mt-3 mb-3">{{$t('(三)	存在如下情形时，我们可能会对您名下taoify.shop用户账户暂停或终止提供taoify.shop用户服务:')}}</div>
        <ul>
          <li>{{$t('您违反了法律法规的规定或本协议的约定。')}}</li>
           <li>{{$t('您的用户账户可能存在风险的(比如您的taoify.shop用户账户长期未被使用月存在一定安全隐患)。')}}</li>
            <li>{{$t('您在参加市场活动时涉及到侵权或违反商业道德，违反诚实信用原则。')}}</li>
             <li>{{$t('您遭到他人投诉，且对方已经得供了一定证据。')}}</li>
              <li>{{$t('您可能错误地操作他人账户，或者将他人账户进行了身份验证。')}}</li>
        </ul>
        <div class="content mt-3 mb-3">{{$t('(四)	taoify.shop用户服务规则')}}</div>
        <ul>
          <li>{{$t('您用户账户的使用记录交易数据等均以我们的系统记录为准，如您对该等数据存有导议的，可及时向我们提出，我们会根据您提供的相关证据积极予以核实。')}}</li>
           <li>{{$t('taoify.shop采用预付金额(账号充值)，后系统扣款、财务扣款/退款的方式来管理您的账户余额和资金流动，您在使用taoify.shop账号的相关服务时请确保账号余额充足。')}}</li>
            <li>{{$t('为了更好地向您提供服务，请您在使用taoify.shop用户服务过程中，遵守本协议内容，taoify.shop用户网站上出现的关于操作的提示及我们发送到您邮件的提示。')}}</li>
           <li>{{$t('我们会以网站公告、电子邮件、站内信等方式向您发送通知，例如通知您交易进展情况，或者提示您进行相关操作，请您及时予以关注。')}}</li>
            <li>{{$t('您需要自行承相使用我们服务期间的货币贬值，汇率波动等风险，您对我们代为收取(账户充值)或代为支付款项(用于买单打包等操作扣款)的金额享有对该等	款项的管辖权和支配权。服务咨询与投诉')}}</li>
           <li>{{$t('在使用我们服务的过程中，如遇到问题，您可以通过拨打+8615038350530或者通过在线客服等方式联系我们，为了保护您及他人的合法权益，如您被他人投诉或投诉他人，我们可能会将您的姓名及身份信息联系方式，企业经营范围投诉相关内容等提供给管辖权的仲裁机关，以便及时解决投诉纠纷；同时，出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的情况下，我们可能会将您的姓名及身份信息、联系方式、企业经营范围、投诉相关内容等提供给争议相对方，但法律法规明确禁止提供的除外。')}}</li>
        </ul>
	

  <div class="content mt-3 mb-3">{{$t('(五)	我们的承诺和声明')}}</div>
        <ul>
          <li>{{$t('您账号中收到的充值款项，会一直以余额的形式存储在您的taoify.shop账号中，我们不会挪作他用。')}}</li>
           <li>{{$t('您使用taoify.shop账号能享受一件代发和店捕代运营全部相关服务。')}}</li>
        </ul>

  <div class="content mt-3 mb-3">{{$t('(六)	服务风险提示')}}</div>
        <ul>
          <li>{{$t('在使用我们的服务时，若您未遵从本协议，事先约定的协议(SOP)或相关网站说明，页面中的操作提示，规则，则我们有权拒绝为您提供相应服务，且我们免于承担损害赔信责任。')}}</li>
           <li>{{$t('请您特别注意，如在taoify.shop用户网站上以页面标明或其他方式表明相关服务系由第三方提供，您在使用该服务过程中如有疑问或发生纠纷，请您与第三方协商解决。')}}</li>
             <li>{{$t('taoify.shop销售的商品均采购自中华人民共和国，主要符合中华人民共和国有关商品质量、安全、卫生、环保、标识等标准，可能与您所在国家的标准存在差异或冲突，您须自行辨别并承担相关风险。')}}</li>
        </ul>
<div class="content mt-3 mb-3">{{$t('(七)	服务费用')}}</div>
<div class="content">{{$t('在您使用我们的服务时，我们有权向您收取服务或者操作费用，具体服务用以您使用我们服务时taoify.shop官方网站展示的价格或您与我们达成的其他书面协议价格为准。除非另有说明或约定，当您完成账户注册时起，则默认授权我们代为收取或代为扣缴您的账户余额作为平台使用的服务或者操作费用。')}}</div>
         
        
        <div class="titlee">
          七、{{ $t("用户合法使用taoify.shop服务的承诺") }}
        </div>
  <div class="content mt-3 mb-2">{{$t('(一)	您需要遵守中华人民共和国相关法律法规及您所属、所居住或开展经营活动或其他业务的国家或地区的法律法规，不得将我们的服务用于非法目的(包括用于禁止或限制交易物品的交易)，也不得以任何非法方式使用我们的服务。')}}</div>
   <div class="content mt-3 mb-3">{{$t('(二)	您不得和用我们的服务从事得害他人合法权益之行为或违反国家法律法规，否则我们有权讲行调查，延迟或停止提供服务，且您要自行承担相关法律责任，如因此导致我们或其他方受损的，您需要承担赔偿责任。')}}</div>
    <div class="content mt-3 mb-3">{{$t('(三)	上述1和2适用的情况包括但不限于:')}}</div>
        <ul>
          <li>{{$t('侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益；')}}</li>
           <li>{{$t('违反保密义务；')}}</li>
              <li>{{$t('冒用/盗用他人账号使用我们的服务；')}}</li>
           <li>{{$t('涉嫌不法交易行为，如贩卖枪支，毒品，禁药，盗版软件，黄色污秽物品，其他我们认为不得使用我们的平台服务进行交易的物品等；')}}</li>
           <li>{{$t('从事可能侵害我们的服务系统、系统知识产权或数据的行为。')}}</li>
        </ul>
        
	



          <div class="content mt-3 mb-3">{{$t('(四)	账户仅限本人使用，承诺按流程开立和使用本人账户')}}</div>
        <div class="content mt-3 mb-3">{{$t('(五)	 您理解，我们的服务有赖于系统的准确运行及操作，若出现系统差错，故障等情形，您同意我们可以采取更正差错，扣划款项等适当纠正措施。')}}</div>
         <div class="content mt-3 mb-3">{{$t('(六)	 您不得对我们的系统和程序采取反向工程手段进行破解，不得对上述系统和程序(包括但不限于源程序、目标程序、技术文档、服务器数据)进行复制、修改编译整合或算改，不得修改成增减我们系统的功能。')}}</div>
          <div class="content mt-3 mb-3">{{$t('(七)	 您不得自行或允许第三方通过非taoify.shop用户官方程序、机器、脚本、爬虫、或其他自动化的方法使用taoify.shop用户服务或获取taoify.shop用户服务相关数据。您仅可通过本人人工操作的方式或者taoify.shop官方代运营操作的方式来使用taoify.shop用户服务。')}}</div>
       
       
        
        <div class="titlee">{{ $t("八、用户权益保障及信息保护") }}</div>
             <div class="content mt-3 mb-3">{{$t('(一)	用户权益保障承诺')}}</div>
                   	<div class="content">{{$t('我们一直事承“极致服务，客户第一”的原则，努力为您在国际电商经营中保驾护航。为保障您在使用服务时的合法权益，我们向您承诺保障您的知情权，自主选择权、公平交易权、受尊重权和客户信息安全权。')}}</div>
                   <div class="content mt-3 mb-3">{{$t('(二)	用户资金保障')}}</div>
                         	<div class="content">{{$t('我们始终重视保护您的资金安全，承诺不挪用，占用用户资金。如您在使用我们服务的过程中，由于您的taoify.shop用户账户被盗用造成资金损失，我们将按照责任双方台同的约定为您提供资金保障和赔偿。')}}</div>
                         <div class="content mt-3 mb-3">{{$t('(三)	信息保护')}}</div>
                               	<div class="content">{{$t('出于技术原因和提升您的用户体验需要，我们将通过Cookies技术，当您的网络浏览器访问taoify.shop服务或由我们或代表我们在其它网站上提供的其他内容时，自动收集并存储您在taoify.shop的使用，行为信息，并在经过数据脱敏使之不再指向，关联到您个人的身份信息时，自由使用脱敏后的纯商业数据。我们将始终高度重视用户的信息保护。未经您的同意，不会将您的个人资料，商业秘密等信息供给何第三方。如果您相删除cookies，或进一步了解收集的信息，我们希望您认真并完整党读我们的《隐私政策》，这将更有助于保障您的个人信息安全。')}}</div>
        <div class="titlee">{{ $t("九、不可抗力、免责及责任限制") }}</div>
         <div class="content mt-3 mb-2">{{$t('(一)	免责条款	')}}</div>
          <div class="content">{{$t('因下列原因导致我们无法正常提供服务，我们免于承担责任:')}}</div>
        <ul class="my-3 mb-2">
          <li>{{$t('我们的系统停机维护或升级;')}}</li>
           <li>{{$t('因台风，地震，洪水、雷电或恐怖袭击等不可抗力原因:')}}</li>
           <li>{{$t('您的电子设备软硬件和通信线路，供电线路出现故障:')}}</li>
           <li>{{$t('因您操作不当或通过非经我们授权或认可的方式使用我们服务:')}}</li>
           <li>{{$t('因病毒，木马，恶意程序攻击，网络拥堵，系统不稳定，系统或设备故障，通讯故障，电力故障，银行原因，第三方服务瑕疵或政府行为等原因。')}}</li>
        </ul>
<div class="content">{{$t('尽管有前款约定，我们将采取合理行动积极促使服务恢复正常。')}}</div>
<div class="content mt-3 mb-3">{{$t('(二)	责任限制')}}</div>
<div class="content">{{$t('我们可能同时为您及您的竞争对手方提供服务，您同意对我们可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此主张我们在提供服务时存在法律上的瑕疵。')}}</div>

         
        <div class="titlee">{{ $t("十、配套规则和协议") }}</div>
         <div class="content mt-3 mb-3">{{$t('为了您更好地使用taoify.shop用户服务，您还需要阅读并遵守taoify.shop用户网站公示的其他协议或规则。前述协议或规则的部分条款被认定无效的，不影响其他内容的效力。')}}</div>
        <div class="titlee">{{ $t("十一、知识产权的保护") }}</div>
         <div class="content">1. {{$t('我们及关联公司的系统及taoify.shop网站上的内容，包括但不限于图片、网站架构、网站画面的安排、网页设计，均属于我们公司依法拥有的知识产权，包括但不限于商标权、专利权、商业秘密等。')}}</div>
        <div class="content">2.	{{$t('非经我们或关联公司书面同意，请勿擅自使用，修改，反向编译，复制，公开传播，改变，散布，发行或公开发表BuckvDrop网站程序或内容。')}}</div>
        <div class="content">3.	{{$t('尊重知识产权是您应尽的义务，如有违反，您需要承担损害赔偿责任。')}}</div>
        <div class="content">4.	{{$t('taoify.shop销售的商品主要符合中华人民共和国的相关知识产权，但因知识产权的地域性因素影响，可能会侵犯您所在国家的相关知识产权，您须自行辨别，并放弃向taoify.shop平台主张索赔的权利。')}}</div>
        <div class="content">5.	{{$t('如果任何组织或个人认为taoify.shop网站上的任何内容可能侵犯其知识产权，可以通过在线客服或邮箱97668216@qq.com及时通知我们，我们会在收到通知后核实，并删除侵权内容，但不承拍任何相关法律责任')}}</div>
        <div class="titlee">{{ $t("十二、法律适用") }}</div>
        <div class="content">1.	{{$t('本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生的争议，均应依照中华人民共和国法律予以处理。taoify.shop拥有和保留本协议的最终解释权。')}}</div>
        <div class="content">2.	{{$t('因本协议看行所产生的一切争议，双方应首先友好协商解决，如协商不成的，仟何一方有权向深圳国际仲裁院申请仲裁。仲裁地为中国广东省深圳市仲裁语言为中文。')}}</div>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.titlee {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  line-height: 25px;
  margin-top: 25px;
  margin-bottom: 10px;
}
.content {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #6a6e79;
}
li {
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  color: #6a6e79;
  margin-left: 20px;
  list-style: initial;
}
</style>